import React from "react"

import PrivateRoute from "../../components/privateRoute"
import AccessDetails from "../../components/Settings/access"

const Access = props => {
  return <PrivateRoute component={AccessDetails} location={props.location} />
}

export default Access
