import React from "react"
import { Accordion } from "react-bootstrap"

import ChangePassword from "./AccessingSwiftCloud/changePassword"
import SettingsLayout from "./settingsLayout"

const Access = () => {
  return (
    <SettingsLayout title="Accessing SwiftCloud" iconClass="box-arrow-in-right me-2" isBusinessPage="0">
      <div className="row g-0 pt-5">
        <div className="col">
          <div className="accordion" id="SwiftCloudSettings">
            <div className="accordion-item">
              <h2
                className="accordion-header accordion-button"
                data-bs-toggle="collapse"
                data-bs-target="#generalSettings"
                aria-expanded="false"
                aria-controls="generalSettings"
              >
                <i className="bi bi-box-arrow-in-right me-2" /> Accessing SwiftCloud
              </h2>
              <div
                id="generalSettings"
                className="accordion-collapse collapse show"
                aria-labelledby="generalSettings"
                data-bs-parent="#SwiftfCloudSettings"
              >
                <div className="accordion-body">
                  <div className="row g-0 block-content border-bottom">
                    <div className="col-lg-12">
                      <Accordion>
                        <ChangePassword />
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}

export default Access
